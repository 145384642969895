import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { ITestDataType } from 'shared/contexts/TestContext'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

interface IPostNebecaTest {
  sentInfo: ITestDataType
}

const useNebecaTestMutation = () => {
  const postNebecaTest = useCallback(async (data: IPostNebecaTest) => {
    return await axios.post(
      `${window.apiEndpoint}/progress/v1/bootcamp`,
      convertCaseList(data, ConvertType.SNAKE),
    )
  }, [])

  const mutation = useMutation({ mutationFn: postNebecaTest })

  return mutation
}

export default useNebecaTestMutation
